import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'

import '../../pricing-pages/styles.scss'
import Layout from '../../../components/Layout'
import Content, {HTMLContent} from '../../../components/Content'
import SharedJumbotron from '../../../components/SharedJumbotron'
import DownloadNow from '../../../components/DownloadNow'

type BondPageProps = {
  content: Node.isRequired,
  contentComponent: any,
  downloadNow: {
    mainText: string,
    subText: string,
    image: any,
  },
  headerImage: any,
}

export const BondPageTemplate = (props: BondPageProps) => {
  const PostContent = props.contentComponent || Content
  const {content, headerImage, downloadNow} = props

  return (
    <div className="pricing-pages">
      <SharedJumbotron headerImage={headerImage} />

      <section className="blog-post-container" style={{paddingBottom: '250px'}}>
        <div className="container content">
          <div className="row justify-content-center">
            <div className="col col-10 blog-container">
              <h3>
                BOND
              </h3>
              {/* <PostContent content={content} /> */}
              <h5>Commissions and Platform Fees</h5>
              <div className="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Charged by</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>Platform Usage Fee</td>
                    <td>0.001 * Bond Face Value</td>
                    <td>Snowball X</td>
                  </tr>
                  <tr>
                    <td>Service Fee</td>
                    <td>10 USD</td>
                    <td>Snowball X</td>
                  </tr>
                </table>
              </div>
              <p>
                Note: A basic platform fee of 10 USD is charged per order, and commission fees are determined based on the bond face value for both buying and selling, with charges applied in both directions.
                <br />
              </p>
              <h5>Example:</h5>
              <p>For a U.S. Treasury bond order with a face value of 500,000 USD:</p>
              <p>Charge = 10 USD (platform fee) + 500 USD (commission) = 510 USD</p>
              <p>Note: The commission part will not exceed 1.6% of the transaction value at most.</p>
              <p><strong>Example:</strong> For a transaction with a bond face value of 100,000 USD, the commission according to Snowball's standard would be: 100,000 * 0.1% + 10 = 110 USD; However, since the client's transaction bond value is 3,000 USD, the commission fee cannot exceed 3,000 x 1.6% = 48.00 USD, and the final charge is 48.00 USD.</p>
              <br />

              <h5>Third-Party Collection Items</h5>
              <p>Snowball X collects third-party service fees on behalf, which specifically include:</p>
              <div className="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Maximum Charge</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>FINRA Transaction Activity Fee (only for USD-denominated bonds)</td>
                    <td>0.00092 USD * quantity sold</td>
                    <td>0.92 USD</td>
                  </tr>
                  <tr>
                    <td>Exchange Fee</td>
                    <td>Refer to Bond Exchange charged fee</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Custody Fee</td>
                    <td>None</td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const BondPage = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <Layout footerLinks={post.frontmatter.footerLinks}>
      <BondPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        downloadNow={post.frontmatter.downloadNow}
        headerImage={post.frontmatter.headerImage}
      />
    </Layout>
  )
}

BondPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BondPage

export const pageQuery = graphql`
  query Bond($id: String!) {
    markdownRemark(id: {eq: $id}) {
      id
      html
      frontmatter {
        headerImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        downloadNow {
          mainText
          subText
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        footerLinks {
          firstRow {
            name
            href
          }
          secondRow {
            name
            href
          }
        }
      }
    }
  }
`
